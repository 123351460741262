<template>
  <Dialog v-model:visible="show" :header="name" @hide="dialogHide">
    <div style="background: black;padding-top: 20px;border-radius: 4px">
      <!-- <img :style="{'display':(isLoading?'none':'block')}" class="picture" id="mainImg" width="758" height="426.375"/> -->
      <video style="width:758px;height:430px" autoplay controls id="videoElement"></video>
    </div>
  </Dialog>
</template>
<script>
import flvjs from "flv.js/dist/flv.min.js";
export default {
  data() {
    return {
      show: false,
      name: null,
    };
  },

  methods: {
    init(data, name) {
      this.name = name;
      //   console.log(data);
      let account = null;
      let password = null;
      let secret = null;
      let clientId = null;
      let jfSerialNum = null;
      let devId = data[0].devDid;
      data.forEach((item) => {
        switch (item.name) {
          case "account":
            account = item.value;
            break;
          case "password":
            password = item.value;
            break;
          case "secret":
            secret = item.value;
            break;
          case "clientId":
            clientId = item.value;
            break;
          case "jf_serial_num":
            jfSerialNum = item.value;
            break;
          default:
            return;
        }
      });
      this.show = true;
      this.$http(
        "/device/getJfLiveUrl",
        "get",
        {
          account: account,
          password: password,
          secret: secret,
          clientId: clientId,
          devId: devId,
          jfSerialNum: jfSerialNum,
        },
        (res) => {
          this.play(res.data.flv_live_url);
        }
      );
    },
    play(url) {
      if (flvjs.isSupported()) {
        let videoElement = document.getElementById("videoElement");
        let flvPlayer = flvjs.createPlayer(
          {
            type: "flv", //媒体类型 默认是flv
            isLive: true, // 是否是直播流
            hasAudio: true, // 是否有音频
            hanVideo: true, // 是否有视频
            url: url,
            withCredentials: false,
          },
          {
            autoCleanupMinBackwardDuration: true, // 清除缓存 对 SourceBuffer 进行自动清理
          }
        );
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer
          .play()
          .then(() => {
            console.log("视频播放成功");
          })
          .catch(() => {
            console.log("视频播放失败");
          });
      }
    },
    dialogHide() {
      clearInterval(this.interval);
      this.interval = null;
      this.$emit("close", true);
    },
  },
};
</script>