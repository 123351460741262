<template>
  <div class="card" style="padding: 2rem 3rem">
    <h5 class="p-text-bold p-d-flex p-justify-between p-align-center">专家建议</h5>
    <div class="suggest-card" style="position:relative">
      <div class="suggest-line">
        <span class="suggest-line-item p-col-6">建议收割时间：{{ suggest ? suggest.bestTime : '-' }}月份</span>
        <span class="suggest-line-item p-col-3">行间距：{{ suggest ? suggest.hangjianju : '-' }}cm</span>
        <span class="suggest-line-item p-col-3">沟宽（穴直径）：{{ suggest ? suggest.goukuan : '-' }}cm</span>
      </div>
      <div class="suggest-line">
        <span class="suggest-line-item p-col-6">推荐收割方式：{{ suggest ? suggest.service : '-' }}</span>
        <span class="suggest-line-item p-col-3">株间距：{{ suggest ? suggest.zhujianju : '-' }}cm</span>
        <span class="suggest-line-item p-col-3">沟深（穴深）：{{ suggest ? suggest.goushen : '-' }}cm</span>
      </div>
      <span v-permission="['planProject','88','xinzengjianyi']" class="record-button record-button-update" @click="userSuggest()" style="position:absolute;top:3rem;right:2rem"><i class="pi pi-send"></i></span>
      <div id="harvestSuggest">
        <div class="comment" style="padding: 0" v-for="comm in suggestList">
          <span class="p-mr-3">{{ new Date(comm.time).toLocaleDateString() }}:</span>
          <span style="word-wrap:break-word;word-break:break-all;">{{ comm.comment }}</span>
        </div>
      </div>
      <div v-if="suggestList&&suggestList.length>6" style="width:100%;text-align:center;height;1.5rem;cursor:pointer">
        <i v-if="showChevron" class="pi pi-chevron-down p-mr-2" style="color: #12A25D" @click="AllSuggest"></i>
        <i v-else class="pi pi-chevron-up p-mr-2" style="color: #12A25D" @click='dropSuggest'></i>
      </div>
    </div>
    <h5 class="p-text-bold p-d-flex p-justify-between p-align-center">农事记录
      <Button v-permission="['planProject','88','xznsjl']" class="p-button-outlined" @click="$router.push({path:'/harvest/harvest',query: {projId: projectId}})">
        记录
      </Button>
    </h5>
    <div v-if="!list || list.length===0" class="p-text-center">
      暂无记录
    </div>
    <div v-else class="record" v-for="item in list">
      <div class="record-card">
        <div class="record-img" :style="{background:item.images&&item.images.length>0?null:'#EDEFF2'}">
          <my-image style="height: 100%" :src="item.images&&item.images.length>0?($minioUrl+item.images[0].imgPath):empty_img" />
        </div>
        <div class="record-content">
          <strong class="record-type">收割记录</strong>
          <div class="record-line">
            <span class="record-line-item"><i class="pi pi-user p-mr-2" style="color: #12A25D"></i>{{ item.opeName
            }}</span>
            <span class="record-line-item"><i class="pi pi-clock p-mr-2" style="color: #12A25D"></i>
              {{ new Date(item.opeTime).toLocaleDateString() }}
            </span>
          </div>
          <div style="width: 100%;display: block;height: 40px;line-height: 40px;font-size: 15px">面积：{{ item.area }}亩
            &nbsp;&nbsp;&nbsp;&nbsp;
            产量：{{ item.yield }}吨&nbsp;&nbsp;&nbsp;&nbsp;
            费用：{{ item.price }}元
          </div>
        </div>
        <div class="record-handle">
          <span v-permission="['planProject','88','xinzengpinglun']" class="record-button record-button-update" @click="comment(item)"><i class="pi pi-send"></i></span>
          <span v-permission="['planProject','88','xgnsjl']" class="record-button record-button-update" @click="update(item)"><i class="pi pi-pencil"></i></span>
          <span v-permission="['planProject','88','scnsjl']" class="record-button record-button-delete" @click="deleteItem(item)"><i class="pi pi-trash"></i></span>
        </div>
      </div>
      <div class="comment" v-for="comm in commentList.filter(one=>{return (item.typem ? item.typem : item.type)==one.recTable && item.id===one.recId })">
        <span class="p-mr-3">{{ new Date(comm.time).toLocaleDateString().replaceAll('/', '-') }}</span>专家评论：
        {{ comm.comment }}
      </div>
    </div>
  </div>
</template>

<script>
import myImage from "@/views/modules/chat/myImage";

export default {
  components: { myImage },
  name: "harvesRecord",
  data() {
    return {
      roleCode: null,
      empty_img: require("@/assets/images/background.png"),
      list: [],
      types: [],
      suggest: null,
      showChevron:true
    };
  },

  props: {
    projectId: {
      type: Number,
    },
    commentList: {},
    suggestList: {},
    buttonsInfo:{},
  },
  mounted() {
    if (document.getElementById("harvestSuggest")) {
        document.getElementById("harvestSuggest").style.maxHeight = "18rem";
        document.getElementById("harvestSuggest").style.overflow = "hidden";
      }
    this.roleCode = JSON.parse(localStorage.getItem("userinfo")).user.roleCode;
    if (localStorage.getItem("suggestInfo" + this.projectId)) {
      this.suggest = JSON.parse(
        localStorage.getItem("suggestInfo" + this.projectId)
      );
    }
    this.getList();
  },
  methods: {
    AllSuggest(){
      this.showChevron=false
      if (document.getElementById("harvestSuggest")) {
        document.getElementById("harvestSuggest").style.maxHeight = "";
        document.getElementById("harvestSuggest").style.overflow = "visible";
      }
    },
    dropSuggest(){
      this.showChevron=true
      if (document.getElementById("harvestSuggest")) {
        document.getElementById("harvestSuggest").style.maxHeight = "18rem";
        document.getElementById("harvestSuggest").style.overflow = "hidden";
      }
    },
    userSuggest() {
      let userId = JSON.parse(localStorage.getItem("userinfo")).user.id;
      let time = new Date();
      const data = {
        type: "收割管理",
        user_id: userId,
        projectId: this.projectId,
        time: time,
      };
      this.$emit("suggest", data);
    },
    comment(item) {
      const data = {
        recId: item.id,
        recTable: item.typem ? item.typem : item.type,
      };
      this.$emit("comment", data);
    },
    getList() {
      this.$axios
        .get("/recHarvestHarvest/getList", {
          params: { projectId: this.projectId },
        })
        .then((res) => {
          this.list = res.data;
          for (let i = 0; i < res.data.length; i++) {
            this.types.push(
              res.data[i].typem ? res.data[i].typem : res.data[i].type
            );
          }
        });
    },
    update(data) {
      this.$router.push({
        path: "/harvest/harvest",
        query: { id: data.id, projId: this.$route.query.projId },
      });
    },
    deleteItem(data) {
      this.$confirm.require({
        message: '您确定要删除这条"收割记录"吗?',
        header: "删除本条数据",
        icon: "pi pi-info-circle",
        acceptLabel: "确定",
        accept: () => {
          this.$axios
            .delete("/recHarvestHarvest/delete/" + data.id)
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "删除成功",
                life: 3000,
              });
              this.getList();
            });
        },
        rejectLabel: "取消",
        reject: () => {
          this.$confirm.close();
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
